import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBlend'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import EmailSubscribeForm from './EmailSubscribeForm'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'
import {subMenuFilter} from './index'
import InfoHours from '../../react/InfoHours'


const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const winesale = "https://images.unsplash.com/photo-1524250426644-e24b385c291a?ixlib=rb-1.2.1&auto=format&fit=crop&w=734&q=80"
export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  return(
    <div className="landing-page">
      <div className="header">
        <div className="hero-wrapper">
          <div className="hero-banner">

            <div>
              {/* <a href="http://toasttab.com/bierhaus-walnut-creek" className="order-online-button">Order Pickup</a> */}
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
               {/* <a href="https://twindragon-newyears.netlify.app" className="order-online-button">Order for 12/31 or 1/1</a> */}

            </div>
          </div>
          <div className="logo-banner">
            <img src="https://afag.imgix.net/twin-dragon-chinese-restaurant/twin-dragon.png?w=1000&auto=format"
             alt="Twin Dragon"
             className="hero-logo"
           />
            {/* <LogoBlend
              themeName={themeName}
              className="hero-logo"
              ></LogoBlend> */}


          </div>
        </div>


      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">Twin Dragon</h2>

              <p className="subtitle">Los Angeles</p>

              <p
              style={{
                fontSize: "2em",
                lineHeight: "1.3em"
              }}
              >
                EMAIL <a href="mailto:twindragon@hidethellama.com">twindragon@hidethellama.com</a> for order issues if restaurant cannot be reached. include full name.
              </p>

              <p className="address">
                <a className="address-link" href="https://www.google.com/maps?q=twin+dragon+restaurant+los+angeles&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjw3M24wZbqAhVaZc0KHZhXA8oQ_AUoAXoECBYQAw">8597 West Pico Blvd.</a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+13236559805">323.655.9805</a>
              </p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+13106577355">310.657.7355</a>
              </p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+13108551550">310.855.1550</a>
              </p>


              {/* <div className="hours">
                <h3 className="hours-title">Pickup / Delivery</h3>
                <p>Sun-Thu</p>
                <p>11am - 9:30pm</p>
                <p>Fri & Sat</p>
                <p>11am - 10:30pm</p>

              </div> */}
            </div>
          </article>

          




        </div>



      </div>
      <InfoHours></InfoHours>

      <div className="whats-new">




        {/* <div className="whats-new__content">
          <article className="introduction">
            <h1><span className="made-in">Made in Walnut Creek,</span><br/>San Francisco chef quality meals in your house</h1>
            <p>Welcome to <strong>Bierhaus Kitchen</strong>, a new service from Bierhaus Walnut Creek. We are introducing a super delicious, easy to get on the table menu designed and prepared by a small team of talented chefs from San Francisco.</p>
            <p>We're excited to introduce this creative program and we hope you will consider Bierhaus Kitchen for your next home meal.</p>
          </article>

          <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/ribs-close-up.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Slow-Cooked</h2>
                <p>Available warm or as reheat-at-home meal kits.</p>
              </div>
            </div>

          </article>
          <article className="full-spread pizzas cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/pizzas.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Wood Fired Pizza</h2>
                <p>Available warm or as reheat kits</p>
              </div>
            </div>

          </article>
          <article className="full-spread pizzas cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/sandwiches.jpg?w=1220&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Sandwiches</h2>
              </div>
            </div>

          </article>
          <article className="full-spread personal">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/desserts.jpg?w=1220&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Dessert in a Jar</h2>
              </div>
            </div>

          </article>


        </div> */}
{/*
        <div className="link-wrapper">
          <a href="/order?bottom" className="order-online-button">View Full Menu</a>
        </div> */}

      </div>

      <div className="menu">
        {/* <div className="menu-header">
          <h2>Our Menu</h2>
        </div> */}

        <AutoMenu subMenuFilter={subMenuFilter} width={600}></AutoMenu>

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      {/* <div className="content">
        <div className="content-grid">
          <div className="email-form">
            <EmailSubscribeForm></EmailSubscribeForm>
          </div>


          <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div>
        </div>
      </div> */}






      {/* <div className="landing-page-footer">
        <Stout></Stout>
      </div> */}
    </div>
  )
}
