
import * as Components from "./Twindragon"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "twindragon"
}

