import React, { useEffect, useContext } from 'react';
import { ShopDataContext } from '../Context/ShopData';
import {get} from 'lodash'

import './InfoHours.scss'


export default function InfoHours () {
  const {settings} = useContext(ShopDataContext)
  console.log(settings)
  const dineIn = getreadableHours(get(settings, 'hourSettings.dineIn', []))
  const pickUp = getreadableHours(get(settings, 'hourSettings.pickUp', []))
  const delivery = getreadableHours(get(settings, 'hourSettings.delivery', []))
 
  console.log(pickUp)
//   return null
  return (
      <div className="info-smart-hours-wrapper">
           <h2
              className='hours-title'
              >Hours</h2>
          <div className="info-smart-hours">
          <div className="dineIn schedule-block-wrapper">
                
                <h3>Dine In</h3>
                   <ScheduleBlock
                       schedule={dineIn}
                   ></ScheduleBlock>
                </div>
             
             <div className="pickup schedule-block-wrapper">
                
             <h3>Pick Up</h3>
                <ScheduleBlock
                    schedule={pickUp}
                ></ScheduleBlock>
             </div>
             <div className="delivery schedule-block-wrapper">
                 <h3>Delivery</h3>
                <ScheduleBlock
                    schedule={delivery}
                ></ScheduleBlock>
             </div>
          </div>
      </div>
  )
}

function ScheduleBlock ({ schedule }) {
   
    return (
        <div className="hours-list">
            {schedule.map(({dayLabel, shortHand, dayHours, isToday}) => {
                return(
                    <div className={isToday ? "day-row today" : "day-row"}>
                        <div 
                        aria-label={dayLabel}
                        className="day-label">
                            {shortHand}
                        </div>
                        {(dayHours.length) ? <div className="hours">
                            {dayHours.map((text) => {
                                return(
                                <div className="interval">
                                    {text}
                                </div>
                                )
                            })}
                        </div> :
                        <div className="hours">
                            <div className="interval">
                                    closed
                                </div>
                        </div>
                        }
                    </div>
                )
             })}
        </div>
    )
    
}



const days = [
    ['Monday',    'Mon', 1],
    ['Tuesday',   'Tue', 2],
    ['Wednesday', 'Wed', 3],
    ['Thursday',  'Thu', 4],
    ['Friday',    'Fri', 5],
    ['Saturday',  'Sat', 6],
    ['Sunday',    'Sun', 0],
]

function getreadableHours(hours) {
    const now = new Date()
    const todayCode = now.getDay() // Sunday - Saturday : 0 - 6
    // const hours = now.getHours() //0 to 23
    // const minutes = now.getMinutes() // 0 to 59
    return days.map(([dayLabel, shortHand, dayCode]) => {
        const isToday = (dayCode === Number(todayCode))
        return(
            {
                dayLabel,
                shortHand,
                isToday,
                dayHours:  Object.values(get(hours, shortHand, []))
                    .map(({from, to}) => `${mToReg(from)} - ${mToReg(to)}`)
            }
        )
    })

  }
  
  const mToReg = ({hour, minute}) => {
    hour = Number(hour)
    let humanHour
    let amPM 
    if (hour === 0) { 
        humanHour = "12", 
        amPM = 'am' 
    }
    if (hour > 12) { 
        humanHour = `${hour - 12}`, 
        amPM = 'pm'  
    }
    if (hour === 12) { 
        humanHour = "12", 
        amPM = 'pm' 
    }
    if (hour < 12) { 
        humanHour = hour, 
        amPM = 'am' 
    }
    if (minute < 10) {
        minute = `0${minute}`
    }
    return(`${humanHour}:${minute}${amPM}`)
  }
  